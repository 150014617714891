/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { FaChevronLeft, FaChevronRight, FaTag } from 'react-icons/fa';
import { Link } from 'gatsby';
import parseDate from '../../helpers/parseDate';
import { FaTwitter, FaFacebookF, FaEnvelope } from 'react-icons/fa';
import { isBrowser } from '../../helpers/isBrowser';
import parseTiptapContent from '../../helpers/parseTiptapContent';

const Post = ({ data, nextPost, previousPost }) => {
  const location = isBrowser && window.location.href;
  const src = data.medium?.url?.proxy;
  return (
    <main sx={{ maxWidth: '900px', mx: 'auto', padding: '1rem' }}>
      <div>
        <h4 sx={{ fontSize: '1.25rem', py: '2rem' }}>
          {data.subtitle} | <span>{parseDate(data.published_date)}</span>
        </h4>
      </div>

      <div
        sx={{
          position: 'relative',
          overflow: 'hidden',
          // height: '100%',
          objectFit: 'cover',
          width: '100%',
        }}
      >
        <div aria-hidden="true" sx={{ width: '100%', pb: '100%' }}></div>
        <picture>
          <source
            srcSet={`${src}?rs:fill/w:100 100w,
                ${src}?rs:fill/w:400 400w,
                ${src}?rs:fill/w:600 600w,
                ${src}?rs:fill/w:1024 1024w,
                ${src}?rs:fill/w:1280 1280w,
                ${src}?rs:fill/w:1280 1280w`}
            sizes="(max-width: 1280px) 100vw, 1280px"
          />
          <img
            sizes="(max-width: 1280px) 100vw, 1280px"
            srcSet={`${src}?rs:fill/w:100 100w,
                ${src}?rs:fill/w:400 400w,
                ${src}?rs:fill/w:600 600w,
                ${src}?rs:fill/w:1024 1024w,
                ${src}?rs:fill/w:1280 1280w,
                ${src}?rs:fill/w:1280 1280w`}
            src={src}
            alt=""
            loading="lazy"
            sx={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: '50% 50%',
              opacity: 1,
              transition: 'opacity 500ms ease 0s',
            }}
          />
        </picture>
        <noscript>
          <picture>
            <source
              srcSet={`${src}?rs:fill/w:100 100w,
                  ${src}?rs:fill/w:400 400w,
                  ${src}?rs:fill/w:600 600w,
                  ${src}?rs:fill/w:1024 1024w,
                  ${src}?rs:fill/w:1280 1280w,
                  ${src}?rs:fill/w:1280 1280w`}
              sizes="(max-width: 1280px) 100vw, 1280px"
            />
            <img
              loading="lazy"
              sizes="(max-width: 1280px) 100vw, 1280px"
              srcSet={`${src}?rs:fill/w:100 100w,
                  ${src}?rs:fill/w:400 400w,
                  ${src}?rs:fill/w:600 600w,
                  ${src}?rs:fill/w:1024 1024w,
                  ${src}?rs:fill/w:1280 1280w,
                  ${src}?rs:fill/w:1280 1280w`}
              src={src}
              alt=""
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 1,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </picture>
        </noscript>
      </div>
      <h1 sx={{ fontSize: ['2rem', null, '2.5rem', '3rem'], my: '2rem' }}>{data.title}</h1>
      <div className="parsed">
        {/*dangerouslySetInnerHTML={{ __html: data.description_html }} */}
        {isBrowser && parseTiptapContent(data.description_html)}

        {/* {parseEditorJsData({ content: data.description, scripts: true })} */}
      </div>
      {data.tags && (
        <div sx={{ my: '2rem', display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
          {data.tags.map((tag) => {
            return (
              <Link
                to={`/news-media/tag/${tag.slug}`}
                sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
              >
                <FaTag /> {tag.name}
              </Link>
            );
          })}
        </div>
      )}
      <div sx={{ my: '2rem' }}>
        <p>Share this Post </p>
        <div
          sx={{
            color: '#999999',
            display: 'flex',
            gap: '0.5rem',
            mt: '1rem',
            fontSize: '1.5rem',
            a: {
              p: '0.5rem',
              color: 'inherit',
            },
            'a:hover': {
              color: '#2E6D6A',
            },
          }}
        >
          <a href={`https://twitter.com/intent/tweet?text=${location}`}>
            <FaTwitter />{' '}
          </a>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${location}`}>
            {' '}
            <FaFacebookF />
          </a>
          <a
            href={`mailto:?subject=I wanted you to see this site&body=Check out this site: ${location}`}
          >
            {' '}
            <FaEnvelope />
          </a>
        </div>
      </div>

      <div>
        <div
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            pb: '1.5rem',
            mt: '1rem',
            gap: '2rem',
            borderBottomWidth: '1px',
          }}
        >
          <div
            sx={{
              flex: [null, null, '0 0 calc(50% - 1rem)'],
              maxWidth: [null, null, 'calc(50% - 1rem)'],
              py: '1.5rem',
              textAlign: 'left',
            }}
          >
            {previousPost && (
              <>
                <Link
                  to={`/news-media/${previousPost.slug}`}
                  sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                >
                  <span>
                    <FaChevronLeft />
                  </span>
                  <div>
                    <span sx={{ fontSize: '1rem', textTransform: 'uppercase' }}>Previous Post</span>
                    <h3>{previousPost.title}</h3>
                  </div>
                </Link>
              </>
            )}
          </div>
          <div
            sx={{
              flex: [null, null, '0 0 calc(50% - 1rem)'],
              maxWidth: [null, null, 'calc(50% - 1rem)'],
              ml: 'auto',
              py: '1.5rem',
              textAlign: 'right',
            }}
          >
            {nextPost && (
              <>
                <Link
                  to={`/news-media/${nextPost.slug}`}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div>
                    <span sx={{ fontSize: '1rem', textTransform: 'uppercase' }}>Next Post</span>
                    <h3>{nextPost.title}</h3>
                  </div>
                  <span>
                    <FaChevronRight />
                  </span>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Post;
